import store from '../store'

let words = {
    ru: {
      "title.part1":"Все секреты",
      "title.part2":"астрологии",
      "title.part3":"в телефоне",

      "subtitle":"Добро пожаловать в мир познания себя через астрологию и нумерологию.",

      "middle.descr1":"Натальная карта раскроет сильные и слабые стороны вашей личности.",
      "middle.descr2":"Биоритмы помогут настроиться на ваш личный ритм жизни.",

      "bottom.contact":"Связаться с нами:",
      "bottom.support":"Тех. поддержка:",
    },
    en: {
      "title.part1":"The secrets of",
      "title.part2":"all astrology",
      "title.part3":"in your phone",

      "subtitle":"We make brand new products that will change your life.\n\n- marketing\n- promotion\n- site setup\n- landing page setup\n- marketing strategy analysis\n\n<b>Numia Ltd</b> is a team of more than 50 people focused on results. Implementation of products from scratch.",

      "middle.descr1":"The natal chart will reveal the strengths and weaknesses of your personality.",
      "middle.descr2":"Biorhythms will help you tune in to your personal rhythm.",

      "bottom.contact":"Contact with us:",
      "bottom.support":"Users support:",

      "title.new":"Numia LTD is an innovative approach to the implementation of mobile applications.",
    }
}

export default (id) => {
    return words[store.getters.lang][id] ?? ("Not found " + id);
}
