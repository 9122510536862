<template>

  <div class="gcontainer">

      <img :src="require(`@/resources/stars_${device}.gif`)" loading="lazy" class="back">

      <div class="gradient"></div>

      <img :src="require('@/resources/sun.png')" class="sun"/>

      <div class="middle-container">
        <img :src="require(`@/resources/phones_${this.lang}_${device}.png`)" class="phones">
      </div>

      <img :src="require('@/resources/stars.png')" class="stars">
      <img :src="require('@/resources/moon.png')" class="moon">
      <img :src="require('@/resources/arrow1.png')" class="arrow1">
      <img :src="require('@/resources/arrow2.png')" class="arrow2">
      <img :src="require('@/resources/arrow3.png')" class="arrow3">
      <img :src="require('@/resources/arrow4.png')" class="arrow4">
      <div class="arrow1_text">{{$t("middle.descr1")}}</div>
      <div class="arrow2_text">{{$t("middle.descr2")}}</div>

  </div>

</template>

<script>
export default {
  components: {

  },

  data: () => ({
    //
  }),

  computed: {
    lang() {
      return this.$store.state.lang;
    },
    device() {
      return this.$store.state.device;
    }
  },

  methods: {

  },

  mounted() {

  }
};
</script>

<style scoped>

  .gcontainer {
    position: relative;
    height: 700px;
    margin-top: 100px;
  }
  .middle-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .phones {
    width: 55%;
  }
  .back {
    left: calc((100% - 100vw) * 0.5);
    width: 100vw;
    height: 100%;
    position: absolute;
  }
  .gradient {
    background: radial-gradient(ellipse 70% 50%, #180C2700, #180C27);
    left: calc((100% - 100vw) * 0.5);
    width: 100vw;
    height: 100%;
    position: absolute;
  }

  .stars {
    position: absolute;
    width: 80px;
    right: 150px;
    top: -50px;
  }

  .moon {
    position: absolute;
    width: 80px;
    left: 150px;
    bottom: 50px;
  }

  .arrow1 {
    position: absolute;
    left: 200px;
    top:40%;
  }

  .arrow2 {
    position: absolute;
    right: 170px;
    top:55%;
  }

  .arrow3 {
    display: none;
  }

  .arrow4 {
    display: none;
  }

  .arrow1_text {
    position: absolute;
    left: 120px;
    top:55%;
    font-family: "Manrope";
    font-size: 16px;
    color: #ffffff;
    width: 200px;
  }

  .arrow2_text {
    position: absolute;
    right: 50px;
    top:70%;
    font-family: "Manrope";
    font-size: 16px;
    color: #ffffff;
    width: 200px;
  }

  .sun {
    width: 170px;
    margin-top: -100px;
    margin-left: 100px;
    position: absolute;
  }

  /* Комп */
  @media (min-width: 1080px) {
    .tablet { display: none }
    .mobile { display: none }
  }

  /* Планшет */
  @media (min-width: 710px) and (max-width: 1080px) {
    .laptop { display: none }
    .mobile { display: none }
    .gcontainer {
      margin-top: 180px;
      height: 500px;
    }
    .sun {
      width: 120px;
      margin-top: -100px;
      margin-left: 50px;
    }
    .stars {
      position: absolute;
      width: 80px;
      left: 45%;
      top: -100px;
    }
    .arrow1 {
      left: 100px;
    }
    .arrow1_text {
      left: 20px;
      top:55%;
    }
    .arrow2 {
      display: none;
    }
    .arrow3 {
      display: block;
      position: absolute;
      right: 30%;
      top:-10%;
      height: 80px;
    }
    .arrow2_text {
      right: 8%;
      top:-16%;
      width: 20%;
    }
    .moon {
      left: auto;
      right: 80px;
      bottom: 80px;
    }
  }

  /*Телефон*/
  @media (max-width: 710px) {
    .laptop { display: none }
    .tablet { display: none }
    .gcontainer {
      margin-top: 180px;
      height: 350px;
    }
    .phones {
      width: 75%;
    }
    .sun {
      width: 120px;
      margin-top: -100px;
      margin-left: 50px;
    }
    .stars {
      position: absolute;
      width: 80px;
      left: 45%;
      top: -100px;
    }
    .arrow1 {
      left: 30px;
      top:auto;
      bottom:10%;
    }
    .arrow1_text {
      left: 20px;
      top:auto;
      bottom: -15%;
      font-size: 14px;
      width: 150px;
    }
    .arrow2 {
      display: none;
    }
    .arrow3 {
      display: block;
      position: absolute;
      right: 150px;
      top:-20%;
      height: 80px;
    }
    .arrow2_text {
      right: 10px;
      top:-26%;
      font-size: 14px;
      width: 150px;
    }
    .moon {
      left: auto;
      right: 40px;
      bottom: -60px;
    }
  }

  /*Мелкий телефон*/
  @media (max-width: 550px) {
    .laptop { display: none }
    .tablet { display: none }
    .gcontainer {
      height: 350px;
    }
    .phones {
      width: 92%;
    }
    .sun {
      width: 80px;
      margin-top: -150px;
      margin-left: 20px;
    }
    .stars {
      width: 50px;
    }
    .arrow1 {
      left: 30px;
      top:33%;
      width: 55px;
      display: none;
    }
    .arrow1_text {
      left: 20px;
      top: auto;
      bottom:-30%;
      font-size: 14px;
      width: 120px;
    }
    .arrow3 {
      display: block;
      position: absolute;
      right: 15%;
      top:-10%;
      height: 60px;
    }
    .arrow2_text {
      right: 5%;
      top:-45%;
      font-size: 14px;
      width: 120px;
    }
    .arrow4 {
      display: block;
      position: absolute;
      left: 5%;
      bottom:5%;
      height: 80px;
    }
    .moon {
      width: 50px;
      left: auto;
      right: 55px;
      bottom: -100px;
    }
  }

</style>
