<template>

  <div>

    <div class="top">
      <img :src="require('@/resources/logo.png')" class="logo"/>
      <span class="top-title">Numia Ltd</span>
    </div>

    <div class="middle">

      <!--<div class="middle-title-line">
        <span class="middle-title">{{$t("title.part1")}}</span>
        <div>
          <div class="middle-title-right absolute">{{$t("title.part2")}}</div>
          <div class="ellipse">
            <img :src="require('@/resources/ellipse.png')" class="ellipseImage"/>
        </div>
        </div>
      </div>-->

      <div class="middle-title">{{$t("title.new")}}</div>

      <div class="middle-panel">
        <div class="left-panel">
          <div class="text" v-html="$t('subtitle')"></div>

          <img :src="require('@/resources/download.png')" class="download" @click="onDownload"/>
        </div>
        <div class="right-panel">
          <div class="windows">
            <img :src="require(`@/resources/w1_${device}.png`)" class="window"/>
            <img :src="require(`@/resources/w2_${device}.png`)" class="window"/>
            <img :src="require(`@/resources/w3_${device}.png`)" class="window"/>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  components: {

  },

  data: () => ({
      id: 0
  }),

  computed: {
    device() {
      return this.$store.state.device;
    }
  },

  methods: {
      onDownload() {

          //https://numia.club/?utm_source=facebook_web&utm_medium=cpc&utm_campaign=Numia_w2a_US_061221_Sub_DI_newCreo&utm_term=%7B%7Badset.name%7D%7D&utm_content=%7B%7Bad.name%7D%7D

          if (this.$get != undefined) {
              const onelinkGenerator =  new window.AF.OneLinkUrlGenerator(
              {
                  oneLinkURL: "https://numiaclub.onelink.me/5Aej/",//"https://numiaclub.onelink.me/5Aej/62a6fbdd/",
                  pidKeysList: ['utm_source', 'incoming_media_source'],
                  campaignKeysList: ['utm_campaign', 'incoming_campaign']
              });
              onelinkGenerator.setAfSub1("fbclid"); 
              const url = onelinkGenerator.generateUrl();

              if (url == null) {
                  url = `https://numiaclub.onelink.me/5Aej/?pid=${this.$get["utm_source"]}&c=${this.$get["utm_campaing"]}&af_sub1=${this.$get["fbclid"]}&fbclid=${this.$get["fbclid"]}`;
              }

              //console.log(url);
              //fetch(`https://a-prokudin.node-api.numerology.dev-03.h.involta.ru/?v=5&action=click&url=${encodeURIComponent(url)}&id=${this.id}`);

              if (url == null) window.open('https://apps.apple.com/ru/app/id1463384356', '_blank');
              else window.open(url, '_blank');
          } else {
              window.open('https://apps.apple.com/ru/app/id1463384356', '_blank');
          }
      },

      getCookie(name) { 
          const value = `; ${document.cookie}`; 
          const parts = value.split(`; ${name}=`); 
          if (parts.length === 2) return parts.pop().split(';').shift(); 
          return null;
      } 
  },

  mounted() {
      //document.addEventListener('click', this.onDownload);

      this.id = parseInt(Math.random() * 1000);

      //fetch(`https://a-prokudin.node-api.numerology.dev-03.h.involta.ru/?v=5&get=${encodeURIComponent(window.location.href)}&id=${this.id}`);
  }
};
</script>

<style scoped>

  .logo {
    width: 50px;
    height: 50px;
  }

  div {
    font-family: "Manrope";
    color: #ffffff;
  }

  .top {
    display: flex;
    align-items: center;
    padding: 20px 50px;
  }

  .middle {
    padding: 20px 50px;
  }

  .top-title {
    font-size: 23px;
    margin-left: 15px;
  }

  .middle-title {
    font-family: "Stinger";
    font-size: 75px;
    line-height: 1.2em;
  }

  .middle-title-line {
    display: flex;
    flex-direction: row;
    margin-bottom: -65px;
  }

  .middle-title-right {
    font-family: "Stinger";
    font-size: 75px;
    margin-left: 25px;
  }

  .ellipse {
    margin-top: -3px;
    margin-left: 5px;
    animation: fadeInAnimation ease 1.0s;
  }

  .absolute {
    position: absolute;
  }

  .text {
    font-family: "Manrope-ExtraLight";
    font-size: 16px;
    white-space: pre-line;
  }

  .middle-panel {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
  }

  .left-panel {
    max-width: 400px;
    display: flex;
    flex-direction: column;
  }

  .download {
    margin-top: 70px;
    width: 211px;
    cursor: pointer;
    transition: all .1s ease-in-out;
  }

  .download:hover {
    transform: scale(1.02);
  }

  .right-panel {
    width: calc(100% - 400px);
  }

  .windows {
    display: flex;
    flex-direction: row;
    padding-left: 110px;
    margin-top: 0px;
  }

  .window {
    width: 330px;
    margin-left: -15px;
  }

  /* Комп */
  @media (min-width: 1080px) {
    .tablet { display: none }
    .mobile { display: none }
  }

  /* Планшет */
  @media (min-width: 710px) and (max-width: 1080px) {
    .laptop { display: none }
    .mobile { display: none }
    .middle-title {
      font-size: 50px;
    }
    .middle-title-right {
      font-size: 50px;
    }
    .ellipseImage {
      width: 325px;
    }
    .top {
      padding: 10px 20px;
    }
    .middle {
      padding: 10px 20px;
    }
    .middle-title-line {
      margin-bottom: -50px;
    }
    .download {
      margin-top: 30px;
    }
    .middle-panel {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
    }
    .windows {
      padding-left: 0px;
      margin-top: 70px;
    }
    .window {
      width: 270px;
      margin-left: -5px;
    }
  }

  /*Телефон*/
  @media (max-width: 710px) {
    .laptop { display: none }
    .tablet { display: none }
    .top {
      padding: 10px 20px;
    }
    .middle {
      padding: 10px 20px;
    }
    .middle-title {
      font-size: 50px;
    }
    .middle-title-right {
      font-size: 50px;
    }
    .ellipseImage {
      width: 325px;
    }
    .middle-title-line {
      display: flex;
      flex-direction: column;
      margin-bottom: -65px;
    }
    .middle-title-line {
      margin-bottom: -20px;
    }
    .download {
      margin-top: 30px;
      width: 160px;
    }
    .middle-panel {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
    }
    .windows {
      padding-left: 0px;
      margin-top: 30px;
    }
    .window {
      width: 200px;
      margin-left: -10px;
    }
  }

  /*Мелкий телефон*/
  @media (max-width: 500px) {
    .laptop { display: none }
    .tablet { display: none }
    .middle-title {
      font-size: 45px;
    }
    .middle-title-right {
      font-size: 45px;
    }
    .ellipseImage {
      width: 290px;
    }
    .window {
      width: 150px;
      margin-left: -5px;
    }
  }

</style>
