<template>

  <div class="bcontainer">
    <div class="contacts">
      <div class="contact-title">{{$t("bottom.contact")}}</div>
      <div class="contact-text"><a href="mailto:numia@numia.club">numia@numia.club</a></div>
      <div class="contact-title mt-12">{{$t("bottom.support")}}</div>
      <div class="contact-text"><a href="mailto:support@numia.club">support@numia.club</a></div>
      <div class="contact-text mt-12" style="color:#ffffff">{{"Since 2020"}}</div>
    </div>

    <div class="hand-side">
      <div class="hand">
        <img :src="require(`@/resources/hand_${device}.png`)" class="hand-image"/>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  components: {

  },

  data: () => ({
    //
  }),

  computed: {
    device() {
      return this.$store.state.device;
    }
  },

  methods: {

  },

  mounted() {

  }
};
</script>

<style scoped>

  .bcontainer {
    display:flex;
    flex-direction: row;
    height: 500px;
  }

  .contacts {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 48px;
  }

  .contact-title {
    font-family: "Stinger";
    font-size: 45px;
    color: #ffffff;
  }

  .contact-text {
    color: #BA62FF;
    font-family: "Manrope";
    font-size: 25px;
  }

  .hand {
    height: 100%;
    margin-right: -200px;
    overflow: hidden;
  }

  .hand-side {
    width: 570px;
    display: flex;
    height: 500px;
  }

  .hand-image {
    height: 700px;
  }

  /* Комп */
  @media (min-width: 1080px) {
    .tablet { display: none }
  }

  /* Планшет */
  @media (min-width: 710px) and (max-width: 1080px) {
    .laptop { display: none }
    .bcontainer {
      margin-top: 20px;
    }
    .contacts {
      padding-left: 24px;
    }
  }

  /*Телефон*/
  @media (max-width: 710px) {
    .bcontainer {
      margin-top: 20px;
    }
    .contact-title {
      font-size: 35px;
    }
    .contact-text {
      font-size: 22px;
    }
    .contacts {
      padding-left: 24px;
    }
    .hand-image {
      height: 450px;
    }
    .hand {
      display: flex;
      align-items: flex-end;
      margin-top: 70px;
    }
    .hand-side {
      width: 300px;
    }
  }

  /*Мелкий телефон*/
  @media (max-width: 550px) {
    .contacts {
      line-height: 1.2;
    }
    .hand-image {
      height: 250px;
    }
    .hand {
      display: flex;
      align-items: flex-end;
      margin-top: 40px;
    }
    .hand-side {
      width: 150px;
    }
  }

</style>
