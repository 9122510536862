import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'
import translates from './helpers/translates'
import appsflyer from './helpers/appsflyer'

Vue.config.productionTip = false
Vue.prototype.$t = translates

new Vue({
  store,
  vuetify,
  created() {
    let uri = window.location.href.split('?');
    if(uri.length >= 2) {
      let vars = uri[1].split('&');
      let getVars = {};
      let tmp = '';
      vars.forEach(function(v) {
        tmp = v.split('=');
        if(tmp.length == 2)
          getVars[tmp[0]] = tmp[1];
      });

      Vue.prototype.$get = getVars
    }
    //onsole.log(uri.length)
  },
  render: h => h(App)
}).$mount('#app')
