<template>
  <v-app>
    <v-container class="mcontainer">
      <Top/>
      <div class="title-2">The <b>Numia</b> project is a space service with more than 10 million users worldwide. We actively cooperate with the best sites and make the world a better place.</div>
      <Middle/>
      <Bottom/>
    </v-container>

    <span class="mobile_notification" style="color: #ffffff; width: 100%; text-align: center; padding: 20px;">Мобильная версия недоступна.</span>
  </v-app>
</template>

<script>
import Top from './components/Top';
import Middle from './components/Middle';
import Bottom from './components/Bottom';

export default {
  name: 'App',

  components: {
    Top,
    Middle,
    Bottom
  },

  data: () => ({
    //
  }),

  mounted() {
    var client = window.navigator ? (window.navigator.language ||
    window.navigator.systemLanguage ||
    window.navigator.userLanguage) : ("en-EN");

    var language = (client.search('-') > 0) ?
    client.substring(0, client.search('-')).toLowerCase() :
    client.toLowerCase();

    if (language == "ru") this.$store.state.lang = "ru";
    else this.$store.state.lang = "en";

    this.$store.state.lang = "en";
  },

  beforeMount() {
    if (window.innerWidth >= 1080) this.$store.state.device = 'big';
    if (window.innerWidth >= 710 && window.innerWidth < 1080) this.$store.state.device = 'medium';
    if (window.innerWidth < 710) this.$store.state.device = 'small';
  }
};
</script>

<style>

  body {
    background-color: #180C27;
    overflow: hidden;
    touch-action: pan-x pan-y;
  }

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .title-2 {
      padding: 30px;
      text-align: center;
      font-family: "Stinger";
      color: #ffffff;
      font-size: 20pt;
  }

  .v-application {
    background-color: #180C27 !important;
  }
  .mcontainer {
    //background-color: #123456;
    max-width: 1445px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
  }

  @font-face {
    font-family: "Stinger";
    src: local("Stinger-Wide-Thin"), url("resources/Stinger-Wide-Thin.ttf") format("truetype");
  }

  @font-face {
    font-family: "Manrope";
    src: local("Manrope"), url("resources/Manrope.ttf") format("truetype");
  }

  @font-face {
    font-family: "Manrope-ExtraLight";
    src: local("Manrope-ExtraLight"), url("resources/Manrope-ExtraLight.ttf") format("truetype");
  }

  html {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
  }

  html::-webkit-scrollbar {
      width: 0px; /* For Chrome, Safari, and Opera */
  }

  img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }

  /* Комп */
  @media (min-width: 1080px) {
    .mobile_notification {
      display: none;
    }
  }

  /* Планшет */
  @media (min-width: 710px) and (max-width: 1080px) {
    .mobile_notification {
      display: none;
    }
  }

  /*Телефон*/
  @media (max-width: 710px) {
    .mobile_notification {
      display: none;
    }
  }

</style>
